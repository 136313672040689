<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('Address list')}}</div>
            </div>
            <template>
                <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange" @row-update="handleUpdate" @row-del="handDel" @row-save="handleSave">
                    <template slot="menuLeft" style="width: calc(100% - 80px);">
                        <el-row :gutter="20">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px; text-align: right; align-self: flex-end;">
                                <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="$refs.crud.rowAdd()"> {{$t('New')}}</el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="$refs.crud.rowEdit(row,index)"></el-button>
                        <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" @click="handDel(row,index)"></el-button>
                    </template>
                    <template slot-scope="{row,index,type}" slot="menuForm">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                        <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                        <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style scoped>
    .dataicon i {
        display: none;
    }

    .othChe .el-checkbox__label {
        color: #409eff !important;
    }
</style>
<script>
    import { List, add, edit, del, TreeList, AddresssInfoByPath } from "@/api/supplierAddress";
    export default {
        data() {
            return {
                obj: {},
                tableData: [],
                AddressOptions: [],
                addDataInfos: { id: "", buildAddress: "", lat: "", lng: "", country:""},
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                },
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        addBtn: false,
                        editBtn: false,
                        header: true,
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        labelPosition: 'top',
                        column: [
                            {
                                label: this.$t('REGION'),
                                prop: 'AddressValue',
                                type: "cascader",
                                checkStrictly: true,
                                placeholder: ' ',
                                dicData: this.AddressOptions,
                                rules: [{ required: true, message: this.$t('Required'), trigger: 'blur' }],
                            },
                            {
                                label: this.$t('ADDRESS DETAILS'),
                                prop: 'Details',
                                placeholder: ' ',

                            },
                            {
                                label: this.$t('FULL ADDRESS'),
                                disabled: true,
                                hide: true,
                                prop: 'Address',
                                placeholder: ' ',
                            },
                            {
                                label: this.$t('REMARK'),
                                prop: 'Remark',
                                placeholder: ' ',
                            },
                            {
                                label: this.$t('LATITUDE'),
                                type: 'number',
                                disabled: true,
                                placeholder: ' ',
                                prop: 'Lat'
                            },
                            {
                                label: this.$t('LONGITUDE'),
                                type: 'number',
                                disabled: true,
                                prop: 'Lng',
                                placeholder: ' ',
                            } 
                        ]
                    },
                }
            }
        },
        created() {
            TreeList().then(res => {
                this.AddressOptions = res.data;
            });
            this.GetList();
        },
        watch: {
            'obj.Details'(val) {
                if (this.obj.AddressValue != '') {
                    this.getAddinfoByPath("Details",this.obj.AddressValue.toString());
                } else {
                    this.obj.Address = val
                }
            },
            'obj.AddressValue'(val) {
                if (val != '')
                {
                    this.getAddinfoByPath("AddressValue",val.toString());
                }
            },
        },
        methods: {
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val;
                this.GetList();
            },//搜索
            searchFrom() {
                this.GetList();
            },//编辑数据
            handleUpdate(row, index, done) {
                edit(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: "success" });
                    this.GetList();
                    this.obj = {};
                    done();
                })
            },//添加数据
            handleSave(row, done) {
                row.AddressValue = row.AddressValue.toString();
                add(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
                    this.GetList();
                    this.obj = {};
                    done();
                })
            },
            //删除数据
            handDel(row, done) {
                this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    del(row.Id).then(() => {
                        this.GetList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    })
                    done();
                })
            },//获取列表显示出数据
            async GetList() {
                List(this.page.currentPage, this.page.pageSize).then(res => {
                    this.tableData = res.data.VendorAddresss;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            getAddinfoByPath(type,e) {
                AddresssInfoByPath(e).then(res => {
                    var Info = res.data;
                    if (Info != null) {
                        this.addDataInfos.id = Info.id;
                        this.addDataInfos.buildAddress = Info.buildAddress;
                        this.addDataInfos.lat = Info.lat;
                        this.addDataInfos.lng = Info.lng;
                        this.addDataInfos.country = Info.country;
                        if (type == "Details")
                        {
                            this.obj.Address = this.obj.Details + " ," + this.addDataInfos.buildAddress;
                        }
                        else
                        {
                            this.obj.Lat = this.addDataInfos.lat;
                            this.obj.Lng = this.addDataInfos.lng;
                            this.obj.Country = this.addDataInfos.country;
                            if (this.obj.Details != '') {
                                this.obj.Address = this.obj.Details + " ," + this.addDataInfos.buildAddress;
                            } else {
                                this.obj.Address = this.addDataInfos.buildAddress;
                            }

                        }


                    }
                });
            }
        }
    }
</script>